import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from '../documentation/index/index.component';
import {PageComponent} from '../../components/page/page.component';
import {QuillModule} from 'ngx-quill';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {TagsService} from '../../services/tags.service';
import {HttpClientModule} from '@angular/common/http';


@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    QuillModule.forRoot(),
  ],
  exports: [
    PageComponent,
    FormsModule,
    CommonModule,
    NgbModule,
    QuillModule
  ],
  providers: []
})
export class SharedModule {
}
