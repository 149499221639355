import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CreateArticle} from '../models/create-article';
import {UpdateArticle} from '../models/update-article';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  url = `${environment.apiUrl}`;
  articles = '/articles';
  categories = '/categories';

  constructor(private http: HttpClient) {

  }

  createArticle(body: CreateArticle) {
    return this.http.post(this.url + this.articles, body);
  }

  updateArticle(idArticle: number, body: CreateArticle) {
    return this.http.put(this.url + this.articles + '/' + idArticle, body);
  }

  getArticle(idArticle: number) {
    return this.http.get(this.url + this.articles + '/' + idArticle);
  }

  getArticles() {
    return this.http.get(this.url + this.articles + '/map');
  }

  getCategories() {
    return this.http.get(this.url + this.categories + '/list');
  }

  getTagsByName(tagName: string) {
    return this.http.get(this.url + '/tags/' + tagName);
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(this.url + '/attachments/upload', formData);
  }

  getArticlesByTag(tagName) {
    return this.http.get(this.url + '/articles?tagName=' + tagName);
  }

}
