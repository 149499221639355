import {Subject} from 'rxjs';

class Loader {
  on: Subject<number>;
  requests = 0;
  state;

  constructor() {
    this.on = new Subject<number>();
    this.state = this.on.asObservable();
    this.on.next(0);
  }

  reset() {
    this.requests = 0;
    this.on.next(0);
  }

  update(value: boolean) {
    if (value === false && this.requests > 0) {
      this.requests -= 1;
    } else if (value === true) {
      this.requests += 1;
    }
    this.on.next(this.requests);
  }
}

const LoaderState = new Loader();
export default LoaderState;
