import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import {AppComponent} from '../app.component';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkToken();
  }

  checkToken() {
    const token = localStorage.getItem('token');
    if (token && new Date(jwt_decode(token).exp * 1000) > new Date()) {
      return true;
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      this.router.navigate(['/auth/login']);
    }
  }
}
