import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from './modules/shared/shared.module';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true}), SharedModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
