import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import LoaderState from '../services/Loader';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/tags') === -1) {
      LoaderState.update(true);
    }
    request = request.clone({
      setHeaders: {
        Authorization: this.authService.currentUser != null ? 'Bearer ' + this.authService.currentUser.token : ''
      }
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          setTimeout(() => {
            LoaderState.update(false);
          }, 300);
        }
        return event;
      }),
      retry(1),
      catchError((error: HttpErrorResponse) => {
        LoaderState.update(false);
        return throwError(error.error);
      })
    );
  }
}
