import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import * as jwt_decode from 'jwt-decode';
import {AuthGuardService} from './guard/auth-guard.service';
import LoaderState from './services/Loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'doc';
  loader = 0;

  constructor(private router: Router,
              public authService: AuthService,
              private authGuardService: AuthGuardService) {
    if (this.authGuardService.checkToken()) {
      this.authService.isSignedIn = true;
    }
    LoaderState.state.subscribe(data => {
      setTimeout(() => {
        this.loader = data;
      }, 100);
    });
  }


  ngOnInit(): void {
    // setTimeout(() => {
    //   this.isSignedIn = true;
    //   this.router.navigate(['/']);
    // }, 2000);
  }

}
