import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {Article} from '../models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  articles: Array<{categoryName: string, items: Article[]}> = [];

  constructor(private rest: RestService) {
  }

  getArticles() {
    this.rest.getArticles()
      .subscribe((response: any []) => {
        this.articles = [];
        Object.keys(response).forEach((key) => {
          this.articles.push({categoryName: key, items: response[key]});
        });
      });
  }
}
