import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = `${environment.apiUrl}${environment.auth}`;
  currentUser: User;
  isSignedIn = false;

  constructor(private http: HttpClient,
              private router: Router) {

    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      this.currentUser = null;
    }

  }

  login(body: any) {
    return this.http.post(this.url + '/token', body)
      .pipe(map((user: User) => {
        this.currentUser = user;
        localStorage.setItem('token', user.token);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.isSignedIn = true;
        return user;
      }));
  }

  logout() {
    localStorage.removeItem('token');
  }

}
