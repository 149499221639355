import {Component, OnInit} from '@angular/core';
import {RestService} from '../../services/rest.service';
import {ArticlesService} from '../../services/articles.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(public articlesService: ArticlesService) {
  }

  ngOnInit() {
    this.loadSideBarData();
  }

  loadSideBarData() {
    this.articlesService.getArticles();
  }

}
